const expenseData = [
   
    {
        id: 1,
        name : "Purchased book" ,
        category : "Books",
        date : "	2023-07-26",
        amount: 500,
        created:"naveen@gmail.com"

    },
    {
        id: 2,
        name : "CBC test" ,
        category : "Health",
        date : "	2023-07-26",
        amount: 5000,
        created:"akash@gmail.com"

    },
    {
      id: 3,
      name : "Boat Earphones" ,
      category : "Electronics",
      date : "	2023-07-25",
      amount: 2000,
      created:"kiran@gmail.com"

  },
  {
    id: 4,
    name : "Dehli Flight" ,
    category : "Travel",
    date : "	2023-07-25",
    amount: 10000,
    created:"akash@gmail.com"

},
{
  id: 5,
  name : "Bangalore Bus" ,
  category : "Travel",
  date : "	2023-07-24",
  amount: 1000,
  created:"akash@gmail.com"

},
{
  id: 6,
  name : "Fees" ,
  category : "Education",
  date : "	2023-07-23",
  amount: 18000,
  created:"kiran@gmail.com"

},
{
  id: 7,
  name : "New TV" ,
  category : "Home appliances",
  date : "	2023-07-22",
  amount: 45000,
  created:"kiran@gmail.com"

},
{
  id: 8,
  name : "Groceries" ,
  category : "Food",
  date : "	2023-07-22",
  amount: 1600,
  created:"akash@gmail.com"

},
{
  id: 9,
  name : "CBC test" ,
  category : "Health",
  date : "	2023-07-22",
  amount: 2000,
  created:"akash@gmail.com"

},
{
  id: 10,
  name : "Bangalore Bus" ,
  category : "Travel",
  date : "	2023-07-21",
  amount: 1000,
  created:"kiran@gmail.com"

},

  
];
export { expenseData };
